import request from '@/utils/http'

export class BaseClass {
  user = '/ucenter/api/User'
  account = '/ucenter/api/Account'
  menu = '/ucenter/api/Menu'
  role = '/ucenter/api/Role'
  config = '/ucenter/api/Config'
  ad = '/ucenter/api/Ad'
  articleType = '/ucenter/api/NewsType'
  article = '/ucenter/api/News'
  actionLog = '/ucenter/api/ActionLog'
  wechartGZH = '/ucenter/api/WechartGZH'
  sms = '/ucenter/api/SMS'

  report = '/api/Report'
  healthRecord = '/api/HealthRecord'

  amp = '/amp/api'

  post (url, data, responseType = 'json', options = {}) {
    return request({
      url, method: 'POST', data, headers: { responseType }, ...options
    })
  }

  put (url, data) {
    return request({
      url, method: 'PUT', data
    })
  }

  patch (url, data) {
    return request({
      url, method: 'PATCH', data
    })
  }

  delete (url, params) {
    return request({
      url, method: 'DELETE', params
    })
  }

  get (url, params) {
    return request({
      url, method: 'GET', params
    })
  }

  upload (url, data) {
    return request({
      url, method: 'post', data, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

  export (url, method, params) {
    const options = {
      url, method, responseType: 'blob', timeout: 0
    }
    if (method === 'POST') {
      options.data = params
    } else {
      options.params = params
    }
    return request(options)
  }
}

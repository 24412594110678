import { BaseClass } from './base'
import defaultConfig from '@/config/default.config'
export class RcClass extends BaseClass {
  resource = '/rc/api/Resource'
  add (data) {
    data.append('ProjectNo', defaultConfig.project_token)
    return this.upload(this.resource + '/AddExt', data)
  }

  gets (params) {
    params.ProjectNo = defaultConfig.project_token
    return this.get(this.resource, params)
  }
}

import router from '@/router'
import NProgress from 'nprogress' // progress bar
import '@/components/styles/nprogress.less'
import { setDocumentTitle } from '@/utils'
import defaultConfig from '@/config/default.config'
import store from '@/store'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false })

// 白名单，不需要验证
const whiteList = [
  'manage-login',
  'wechart-login',
  'amp-login'
]

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.path.indexOf('wechart') !== -1) {
    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title}`))
  } else {
    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${defaultConfig.title}`))
  }

  if (storage.get(ACCESS_TOKEN)) {
    if (to.name === 'manage-login') {
      next({ name: 'manage' })
    } else if (to.name === 'wechart-login') {
      next({ name: 'wechart-index' })
    } else {
      if (store.state.user.menus.length === 0) {
        store.dispatch('GetInfo').then((res) => {
          store.dispatch('GetMenus').then(menus => {
            store.dispatch('GenerateRoutes', { menus }).then(routes => {
              routes.forEach(e => {
                router.addRoute(e)
              })
              next({ ...to, replace: true })
            })
          })
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      next()
    } else {
      next({ name: 'manage-login' })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

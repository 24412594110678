
const app = {
  state: {
    api_host: '',
    host: '',
    version: '',
    collapsed: false,

    SEXS: [{ label: '男', value: 1 }, { label: '女', value: 2 }],
    ADPOSITIONS: [{ label: '开屏广告', value: 1 }, { label: '首页banner广告', value: 2 }],
    ADSTATES: [{ label: '启用', value: true }, { label: '禁用', value: false }],
    ISSUETYPES: [{ label: '发布', value: true }, { label: '未发布', value: false }],

    CATEGORYICONS: [
      { categoryId: 1, icon: 'icon-xiexibao' },
      { categoryId: 2, icon: 'icon-u_71' },
      { categoryId: 3, icon: 'icon-blood' },
      { categoryId: 4, icon: 'icon-oxygen' },
      { categoryId: 5, icon: 'icon-CO2nongdu' },
      { categoryId: 6, icon: 'icon-feiai' },
      { categoryId: 7, icon: 'icon-heart' },
      { categoryId: 8, icon: 'icon-ganbing' },
      { categoryId: 9, icon: 'icon-shenneike' },
      { categoryId: 10, icon: 'icon-shengzhixitong' },
      { categoryId: 11, icon: 'icon-neifenmi' },
      { categoryId: 12, icon: 'icon-xietang' },
      { categoryId: 13, icon: 'icon-zhifang' },
      { categoryId: 14, icon: 'icon-qingxuguanli' },
      { categoryId: 15, icon: 'icon-danao' },
      { categoryId: 16, icon: 'icon-cycle-qiche' },
      { categoryId: 17, icon: 'icon-mianyi' },
      { categoryId: 18, icon: 'icon-a-shoushouzhangbazhang' },
      { categoryId: 19, icon: 'icon-caozuojiankang' },
      { categoryId: 20, icon: 'icon-shalou' }
    ]
  },
  mutations: {
    SET_API_HOST (state, value) {
      state.api_host = value
    },
    SET_HOST (state, value) {
      state.host = value
    },
    SET_VERSION (state, value) {
      state.version = value
    },
    SET_COLLAPSED (state, value) {
      state.collapsed = value
    }

  },
  actions: {}
}

export default app

import storage from 'store'
import { ACCESS_TOKEN, ACCESS_TOKEN_SIMPLE } from '@/store/mutation-types'
import { dataToPromiseTree } from '@/utils'
import { UserClass, WechartClass } from '@/apis/user'
import DefaultConfig from '@/config/default.config'

const api = new UserClass()
const wechart = new WechartClass()
const user = {
  state: {
    token: '',
    token_simple: '',
    menus: [],
    allMenus: [],
    roles: [],
    allRoles: [],
    userInfo: {},
    projectId: 0,
    ampUser: {},
    reportItem: {}
  },
  mutations: {
    SET_TOKEN (state, value) {
      state.token = value
    },

    SET_TOKEN_SIMPLE (state, value) {
      state.token_simple = value
    },

    SET_MENUS (state, value) {
      state.menus = value
    },

    SET_USER_MENUS (state, value) {
      state.allMenus = value
    },

    SET_ROLE (state, value) {
      state.roles = value
    },

    SET_INFO (state, value) {
      state.userInfo = value
    },

    SET_AMP_INFO (state, value) {
      state.ampUser = value
      storage.set('AMP_USER_INFO', value)
    },

    SET_PROJECTID (state, value) {
      state.projectId = value
    },

    SET_REPORTITEM (state, value) {
      state.reportItem = value
      storage.set('REPORTITEM', value)
    }
  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        api.login(userInfo).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          resolve(response.userDto)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 权限菜单
    GetMenus ({ commit }) {
      return new Promise((resolve, reject) => {
        api.menus().then(response => {
          if (response.length === 0) {
            response = [
              {
                name: '首页',
                sign: 'index',
                parentId: 0
              }
            ]
          }
          const pros = dataToPromiseTree(response, 0)

          commit('SET_MENUS', pros)

          resolve(pros)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        api.userInfo().then(response => {
          commit('SET_ROLE', response.roleIds)
          commit('SET_INFO', response)
          resolve(response)
        }).catch(() => {
          reject(new Error('获取用户信息失败 !'))
        })
      })
    },

    // 登出
    Logout ({ commit }) {
      commit('SET_MENUS', [])
      return new Promise((resolve) => {
        api.logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_TOKEN_SIMPLE', '')
          commit('SET_ROLE', [])
          storage.remove(ACCESS_TOKEN)
          storage.remove(ACCESS_TOKEN_SIMPLE)
          resolve()
        })
      })
    },

    WxGZHLogin ({ commit }, code) {
      return new Promise((resolve, reject) => {
        wechart.login(code).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          resolve(response.userDto)
        }).catch(error => {
          reject(error)
        })
      })
    },

    WxGZHRegist ({ commit }, code) {
      return new Promise((resolve, reject) => {
        wechart.regist(code).then(response => {
          storage.set(ACCESS_TOKEN, 'Bearer ' + response.token, 7 * 24 * 60 * 60 * 1000)
          storage.set(ACCESS_TOKEN_SIMPLE, response.token, 7 * 24 * 60 * 60 * 1000)

          commit('SET_TOKEN', response.token)
          commit('SET_TOKEN_SIMPLE', response.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    SnsapiBase () {
      const appid = DefaultConfig.wechart_app_id
      const redirect = DefaultConfig.host + '/wechart/login'
      const scope = 'snsapi_base'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    },

    SnsapiUserinfo ({ commit }, url = '') {
      console.log(url)
      url = url || '/wechart/login'
      const appid = DefaultConfig.wechart_app_id
      const redirect = DefaultConfig.host + url
      const scope = 'snsapi_userinfo'
      const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      location.href = href
    }
  }
}

export default user

const modes = {
  // eslint-disable-next-line no-undef
  test: TEST_MODE,
  // eslint-disable-next-line no-undef
  www: WWW_MODE,
  // eslint-disable-next-line no-undef
  local: LOCAL_MODE
}

// eslint-disable-next-line no-undef
const mode = MODE

const config = {
  mode: mode,
  host: modes[mode].host,
  wechart_app_id: modes[mode].wechartAppId,

  version: process.env.VUE_APP_VERSION,
  check_auth: process.env.VUE_APP_CHECK_AUTH,
  project_token: process.env.VUE_APP_PROJECT_TOKEN,
  title: process.env.VUE_APP_PROJECT_TITLE,
  company: process.env.VUE_APP_COMPANY
}

export default config

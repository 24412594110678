import { BaseClass } from './base'

export class UserClass extends BaseClass {
  modelRef = {
    username: '',
    password: '',
    name: '',
    sex: 0,
    birthDay: null,
    birthDay2: null,
    avatar: '',
    mobile: '',
    roleId: null
  }

  rulesRef = {
    username: [
      {
        required: true,
        message: '请输入用户名'
      }
    ],
    password: [
      {
        required: true,
        message: '请输入密码'
      }
    ],
    name: [
      {
        required: true,
        message: '请输入姓名'
      }
    ]
  }

  // 平台登录
  login (data) {
    return this.post(this.account + '/ManageLogin', data)
  }

  reg (data) {
    return this.post(this.account + '/Reg', data)
  }

  menus () {
    return this.get(this.menu + '/GetUserRoleMenus')
  }

  userInfo () {
    return this.get(this.account + '/UserCache')
  }

  logout () {
    return this.get(this.account + '/Logout')
  }

  psw (data) {
    return this.put(this.account + '/ChangePsw', data)
  }

  gets (params) {
    return this.get(this.user, params)
  }

  create (data) {
    return this.post(this.user, data)
  }

  update (data) {
    return this.put(this.user, data)
  }

  updateSelf (data) {
    return this.put(this.user + '/Self', data)
  }

  updateMobile (mobile) {
    return this.put(this.user + `/UpdateMobile/${mobile}`)
  }

  state (params) {
    return this.get(this.user + `/AllowLogin/${params.id}/${params.state}`)
  }

  setRoles (userId, roleIds) {
    return this.post(this.user + '/SetUserRole', { userId, roleIds })
  }
}

export class ActionLogClass extends BaseClass {
  gets (params) {
    return this.get(this.actionLog, params)
  }
}

export class WechartClass extends BaseClass {
  login (code) {
    return this.get(this.wechartGZH + '/Register', { code })
  }
}

export class SMSClass extends BaseClass {
  getCode (mobile) {
    return this.get(this.sms + `/SendCode/${mobile}`)
  }

  checkCode (mobile, code) {
    return this.get(this.sms + `/VerifyCode/${mobile}/${code}`)
  }
}

import store from '../store'
import defaultConfig from '../config/default.config'
import storage from 'store'
import { ACCESS_TOKEN_SIMPLE } from '@/store/mutation-types'

store.commit('SET_API_HOST', defaultConfig.api_host)
store.commit('SET_HOST', defaultConfig.host)
store.commit('SET_TOKEN', storage.get(ACCESS_TOKEN_SIMPLE))
store.commit('SET_VERSION', defaultConfig.version)

store.commit('SET_AMP_INFO', storage.get('AMP_USER_INFO'))
store.commit('SET_REPORTITEM', storage.get('REPORTITEM'))
